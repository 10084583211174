import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Col, Row } from 'reactstrap';

const CredentialSkeleton = (props) => {
	return (
		<React.Fragment>
			<SkeletonTheme baseColor="#f7fafc" highlightColor="#eff1ff">				
				<Row className="my-1 mx-0">
					<Col xs={12} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
					<Col xs={8} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
				</Row>
                <Row className="my-1 mx-0">
					<Col xs={12} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
					<Col xs={8} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
				</Row>
                <Row className="my-1 mx-0">
					<Col xs={12} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
					<Col xs={8} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
				</Row>
                <Row className="my-1 mx-0">
					<Col xs={12} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
					<Col xs={8} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
				</Row>
                <Row className="my-1 mx-0">
					<Col xs={12} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
					<Col xs={8} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
				</Row>
                <Row className="my-1 mx-0">
					<Col xs={12} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
					<Col xs={8} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
				</Row>
                <Row className="my-1 mx-0">
					<Col xs={12} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
					<Col xs={8} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
				</Row>
                <Row className="my-1 mx-0">
					<Col xs={12} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
					<Col xs={8} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
				</Row>
                <Row className="my-1 mx-0">
					<Col xs={12} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
					<Col xs={8} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
				</Row>
                <Row className="my-1 mx-0">
					<Col xs={12} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
					<Col xs={8} className={"my-2 table-head-load"}>
						<Skeleton height={10} />
					</Col>
				</Row>
			</SkeletonTheme>
		</React.Fragment>
	);
}

export default CredentialSkeleton;