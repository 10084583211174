import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import DetailSkeleton from '../components/Skeleton/DetailSkeleton';
import { PostData } from '../services/FetchData';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { ProductContext } from '../store';

const ShopifySearchLogin = () => {
	const location	= useLocation(),
		navigate	= useNavigate(),
		[searchParams] = useSearchParams(),
		{
			setCurrentWebsiteId
		}	= useContext(ProductContext);
	useEffect(() => {
		if (window.RvBotView) {
			window.RvBotView.destory();
		}
		Cookies.remove("_cb_search_token");
		Cookies.remove("_website_id");
		let context	= searchParams.get("context"),
			returnUrl	= searchParams.get("return_url")
		PostData("ms1", "api/v1/website/getWebsiteInfoByContext", {context: context}, false, false).then(response => {
			if( response !== "Invalid" ){
				if( response.status === "success" ){
					Cookies.set('_cb_search_token', response.data.accessToken)
					// if( response.data.websites && Array.isArray(response.data.websites) && response.data.websites.length > 0 ){
						Cookies.set('_website_id', response.data.websiteId)
						setCurrentWebsiteId(response.data.websiteId)
					// }
					if( returnUrl ){
						return navigate(returnUrl)
					}else{
						return navigate("/search/dashboard")
					}
				}else if( response.status === "failure" ){
					toast.error(response.message)
					
				}else{
					toast.error(response.message)
				}
			}
			return navigate("/auth/login")
		})
	}, [])
	return (
		<div>
			<DetailSkeleton />
		</div>
	);
};

export default ShopifySearchLogin;