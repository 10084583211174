import React, { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import {
	addDays,
	endOfDay,
	startOfDay,
	startOfMonth,
	endOfMonth,
	addMonths,
	startOfWeek,
	endOfWeek,
	isSameDay,
} from "date-fns";

const DateRangePickerComponent = ({ onSelect }) => {
	// Function to format date to "DD Mon YYYY"
	const formatDate = (date) => {
		const day = date.getDate();
		const month = date.toLocaleString("default", { month: "short" });
		const year = date.getFullYear();
		return `${month} ${day}, ${year}`;
	};
	const defineds = {
		startOfWeek: startOfWeek(new Date()),
		endOfWeek: endOfWeek(new Date()),
		startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
		endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
		startOfToday: startOfDay(new Date()),
		endOfToday: endOfDay(new Date()),
		startOfYesterday: startOfDay(addDays(new Date(), -1)),
		endOfYesterday: endOfDay(addDays(new Date(), -1)),
		startOfMonth: startOfMonth(new Date()),
		endOfMonth: endOfMonth(new Date()),
		startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
		endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
	};
	const calendarRef = useRef(null);
	const [maxDate, setMaxDate] = useState(new Date());
	const [selectionRange, setSelectionRange] = useState({
		startDate: defineds.startOfMonth,
		endDate: defineds.endOfToday,
		key: "selection",
	});
	const [displaySelectionRange, setDisplaySelectionRange] = useState({
		startDate: defineds.startOfMonth,
		endDate: defineds.endOfToday,
		key: "selection",
	});
	const [showCalendar, setShowCalendar] = useState(false);

	useEffect(() => {
		let newMaxDate = new Date(selectionRange.startDate),
			maxDate = newMaxDate,
			currentDate = new Date();
		newMaxDate.setMonth(newMaxDate.getMonth() + 2);
		if (newMaxDate.getDate() + 60 > currentDate.getDate()) {
			maxDate = currentDate;
			setMaxDate(currentDate);
		} else {
			setMaxDate(newMaxDate);
		}
	}, [selectionRange.startDate]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (calendarRef.current && !calendarRef.current.contains(event.target)) {
				setShowCalendar(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSelect = (ranges) => {
		setSelectionRange(ranges.selection);
		if (ranges.selection.startDate && ranges.selection.endDate) {
		}
	};

	const toggleCalendar = () => {
		setShowCalendar(!showCalendar);
		setSelectionRange(displaySelectionRange);
	};

	const generateReport = () => {
		setDisplaySelectionRange(selectionRange);
		setShowCalendar(false);
		onSelect(selectionRange);
	};

	const staticRangeHandler = {
		range: {},
		isSelected(range) {
			const definedRange = this.range();
			return (
				isSameDay(range.startDate, definedRange.startDate) &&
				isSameDay(range.endDate, definedRange.endDate)
			);
		},
	};

	const createStaticRanges = (ranges) => {
		return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
	};

	const defaultStaticRanges = createStaticRanges([
		{
			label: "Today",
			range: () => ({
				startDate: defineds.startOfToday,
				endDate: defineds.endOfToday,
			}),
		},
		{
			label: "Yesterday",
			range: () => ({
				startDate: defineds.startOfYesterday,
				endDate: defineds.endOfYesterday,
			}),
		},

		{
			label: "This Week",
			range: () => {
				const today = new Date();
				return {
					startDate: defineds.startOfWeek,
					endDate: today,
				};
			},
		},
		{
			label: "Last Week",
			range: () => ({
				startDate: defineds.startOfLastWeek,
				endDate: defineds.endOfLastWeek,
			}),
		},
		{
			label: "This Month",
			range: () => {
				const today = new Date();
				return {
					startDate: defineds.startOfMonth,
					endDate: today,
				};
			},
		},
		{
			label: "Last Month",
			range: () => ({
				startDate: defineds.startOfLastMonth,
				endDate: defineds.endOfLastMonth,
			}),
		},
	]);

	return (
		<div className="dateRangePickerOuter">
			<button id="dateRange" onClick={toggleCalendar}>
				{formatDate(displaySelectionRange.startDate)} -{" "}
				{formatDate(displaySelectionRange.endDate)}
			</button>
			{showCalendar && (
				<div ref={calendarRef} className="dateRangePicketInner">
					<DateRangePicker
						ranges={[selectionRange]}
						onChange={handleSelect}
						style={{ flexGrow: 1 }}
						maxDate={maxDate}
						moveRangeOnFirstSelection={true}
						retainEndDateOnFirstSelection={true}
						inputRanges={[]}
						staticRanges={[...defaultStaticRanges]}
					/>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							gap: "0.5rem",
						}}
					>
						<div>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => generateReport()}
								style={{
									backgroundColor: "rgb(41 57 78)",
									borderColor: "rgb(41 57 78)",
									color: "#fff",
								}}
							>
								Apply
							</button>
						</div>
						<div>
							<button
								type="button"
								className="btn btn-light"
								onClick={() => toggleCalendar()}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default DateRangePickerComponent;
