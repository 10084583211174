import React, { useContext, useState } from "react";
import { ProductContext } from "../store";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  NavLink,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Cookies from "js-cookie";
import { Link, Navigate, NavLink as NavLinkRRD } from "react-router-dom";

const Header = (props) => {
  const [redirectLogout, setRedirectLogout] = useState(false),
    [dropdownToggle, setDropdownToggle] = useState(false),
    [websiteDropdown, setWebsiteDropdown] = useState(false),
    {
      userData,
      userLoader,
      currentWebsiteId,
      websiteLoader,
      websiteData,
      setCurrentWebsiteId,
    } = useContext(ProductContext);

  const logOut = () => {
    if (window.RvBotView) {
      window.RvBotView.destory();
    }
    Cookies.remove("_cb_search_token");
    Cookies.remove("_website_id");
    // clearInterval(intervalIdRef.current);
    // intervalIdRef.current = null
    setRedirectLogout(true);
  };

  const changeWebsite = (websiteId) => {
    console.log(websiteId)
    Cookies.set("_website_id", websiteId);
    setCurrentWebsiteId(websiteId);
  };

  if (redirectLogout) {
    return <Navigate to="/auth/login" replace />;
  }

  return (
    <div className="header-container">
      <header className="contentHeaderWrapper">
        <div className="contentHeaderInner">
          <div className="contentHeaderLeftWrap">
            {websiteLoader ? (
              <Spinner />
            ) : (
              <>
                <Label className="storeNameLabel">
                  <span className="storeNameLabelHeader">Store</span>
                  <Dropdown
                    direction="bottom"
                    toggle={() => setWebsiteDropdown((prevState) => !prevState)}
                    isOpen={websiteDropdown}
                  >
                    <DropdownToggle className="pr-0" nav>
                      <Button color="link" className="storeNameContentBtn">
                        <div className="storeNameContentWrap">
                          {typeof websiteData[currentWebsiteId] !== "undefined"
                            ? websiteData[currentWebsiteId].websiteURL
                            : ""}
                        </div>
                        <span class="websiteSelectionArrow d-flex flex-column">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="arrowUp"
                          >
                            <path d="m18 15-6-6-6 6"></path>
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="arrowDown"
                          >
                            <path d="m6 9 6 6 6-6"></path>
                          </svg>
                        </span>
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow">
                      <div className="userDropDownMenu websiteDropdown">
                        {Object.keys(websiteData).map((website, index) => {
                          return (
                            <div
                              className="dropdownSubMenu"
                              key={index}
                              onClick={() => changeWebsite(website)}
                            >
                              <div className="d-flex align-item-center justify-content-between">
                                <div className="websiteDropdownLeft">
                                  <p className="cbSubduedText textEllipsis">
                                    {websiteData[website].websiteURL}
                                  </p>
                                </div>
                                <div className="websiteDropdownRight">
                                  <p className="cbSubduedText">
                                    {parseInt(currentWebsiteId) ===
                                    parseInt(website)
                                      ? "Current"
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </Label>
              </>
            )}
          </div>
          <div className="contentHeaderRightWrap">
            {userLoader ? (
              <Spinner />
            ) : (
              <Dropdown
                direction="bottom"
                toggle={() => setDropdownToggle((prevState) => !prevState)}
                isOpen={dropdownToggle}
              >
                <DropdownToggle className="pr-0" nav>
                  <div className="user userFlex">
                    <div
                      className={`avatar uiUserPicture avatarPrimary${
                        (userData.userId - 1) % 10
                      }`}
                    >
                      {userData.firstName.substr(0, 1)}
                      {userData.lastName && userData.lastName.substr(0, 1)}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      aria-hidden="true"
                    >
                      <path d="m6 9 6 6 6-6"></path>
                    </svg>
                  </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow">
                  <div className="userDropDownMenu">
                    <div className="dropdownSubMenu">
                      <div className="dropdownMenuItemUser">
                        <div
                          className={`avatar uiUserPicture avatarPrimary${
                            (userData.userId - 1) % 10
                          }`}
                        >
                          {userData.firstName.substr(0, 1)}
                          {userData.lastName && userData.lastName.substr(0, 1)}
                        </div>
                        <div className="user">
                          <div className="name">
                            {userData.firstName}{" "}
                            {userData.lastName && userData.lastName}
                          </div>
                          <div className="user-login">{userData.email}</div>
                        </div>
                      </div>
                    </div>
                    <div className="dropdownSubMenu">
                      <NavLink
                        to={"/user/settings"}
                        onClick={() =>
                          setDropdownToggle((prevState) => !prevState)
                        }
                        tag={NavLinkRRD}
                      >
                        <span className="dropdownMenuItemUser">
                          <i className="fa-solid fa-sliders"></i>
                          <div className="name">Account Settings</div>
                        </span>
                      </NavLink>
                    </div>
                    <div className="dropdownSubMenu">
                      <span
                        className="dropdownMenuItemUser logout"
                        onClick={(e) => logOut()}
                      >
                        <div className="name">Logout</div>
                        <i className="fa-solid fa-arrow-right-from-bracket"></i>
                      </span>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
