import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NavLink as NavLinkRRD } from "react-router-dom";
import { NavLink } from "reactstrap";
import { SearchRoutes, userRoutes } from "../Routes";
import collapsedIcon from "../assets/images/favicon-32x32.png";

function Sidebar() {
	const location = useLocation(); // Get the current location
	const [isCollapsed, setIsCollapsed] = useState(false);

	// Collapse the sidebar based on the current path
	useEffect(() => {
		if (location.pathname.startsWith("/search")) {
			setIsCollapsed(true);
		} else {
			setIsCollapsed(false);
		}
	}, [location.pathname]); // Run the effect whenever the path changes

	return (
		<div className={`applicationsSidebar ${isCollapsed ? "collapsed" : ""}`}>
			<aside className="applicationRootSidebar">
				<div className="applicationSidebarScroll">
					<div className="scrollIndicatorContent">
						<header className="sidebarHeader">
							{isCollapsed ? (
								<img
									src={collapsedIcon}
									alt="Collapsed Logo"
									className="img-fluid"
								/>
							) : (
								<img
									src={require("./../assets/images/brand/cb-logo-color.png")}
									alt="ConversionBox"
									className="img-fluid"
								/>
							)}
						</header>
						<div className="navigationWrapper">
							<nav>
								<ul className="mainNavigationList">
									{userRoutes.map((userRoute, index) => {
										return (
											<React.Fragment key={index}>
												{userRoute.type === "mainMenu" && (
													<>
														{(index === 1 && !isCollapsed) &&
															<li className="mt-1">
																<span>Products</span>
															</li>
														}
														<li>
															<NavLink
																to={userRoute.layout + userRoute.path}
																tag={NavLinkRRD}
																className={`navItem ${userRoute.itemClass ? userRoute.itemClass : ""
																	}`}
																activeClassName="active"
															>
																{userRoute.menuSvg ? (
																	<div
																		dangerouslySetInnerHTML={{
																			__html: userRoute.menuSvg,
																		}}
																	/>
																) : null}
																<span className="rootNavText">
																	{userRoute.name}
																</span>
															</NavLink>
														</li>
																												
													</>
												)}
											</React.Fragment>
										);
									})}
								</ul>
								{/* <ul className="mainNavigationList collapsedList">
                  {SearchRoutes.map((searchRoute, index) => {
                    return (
                      <React.Fragment key={index}>
                        {searchRoute.type === "searchMenu" && (
                          <NavLink
                            to={searchRoute.layout + searchRoute.path}
                            tag={NavLinkRRD}
                            className={`searchItem ${
                              searchRoute.itemClass ? searchRoute.itemClass : ""
                            }`}
                            activeClassName="active"
                          >
                            {searchRoute.menuSvg ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: searchRoute.menuSvg,
                                }}
                              />
                            ) : null}
                            <span className="rootNavText">
                              {searchRoute.name}
                            </span>
                          </NavLink>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul> */}
							</nav>
							<nav className="rootSidebarBottom">
								<ul className="innerRootSidebarBottom">
									{userRoutes.map((userRoute, index) => {
										return (
											<React.Fragment key={index}>
												{userRoute.type === "bottomMenu" && (
													<NavLink
														to={userRoute.layout + userRoute.path}
														tag={NavLinkRRD}
														className={`navItem ${userRoute.itemClass ? userRoute.itemClass : ""
															}`}
														activeClassName="active"
													>
														{userRoute.menuSvg ? (
															<div
																dangerouslySetInnerHTML={{
																	__html: userRoute.menuSvg,
																}}
															/>
														) : null}
														<span className="rootNavText">
															{userRoute.name}
														</span>
													</NavLink>
												)}
											</React.Fragment>
										);
									})}
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</aside>
		</div>
	);
}

export default Sidebar;
