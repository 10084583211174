import { format, startOfMonth } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ReportHeader from '../../../components/Search/ReportHeader';
import { PostData } from '../../../services/FetchData';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Table } from 'reactstrap';
import DetailSkeleton from '../../../components/Skeleton/DetailSkeleton';
import TableSkeleton from '../../../components/Skeleton/TableSkeleton';
import ReactPaginate from 'react-paginate';

const ProdutsShown = () => {
	const [dateRange, setDateRange] = useState({
													startDate: startOfMonth(new Date()),
													endDate: new Date(),
													key: "selection",
												}),
		[pageLoader, setPageLoader] = useState(true),
		[tableLoader, setTableLoader] = useState(true),
		[totalPageCount, setTotalPageCount]	= useState(0),
		[shownProductsData, setShownProductsData]	= useState([]),
		[limit, setLimit] = useState(25),
		[page, setPage] = useState(0)

	useEffect(() => {
		getReports()
	}, [dateRange, limit, page])

	const getReports = () => {
		setTableLoader(true)
		let websiteId = Cookies.get("_website_id"),
			data = {
				websiteId,
				startDate: format(dateRange.startDate, "yyyy-MM-dd"),
				endDate: format(dateRange.endDate, "yyyy-MM-dd"),
				page: page+1,
				limit,
			}
		PostData("ms1", "api/v1/analytics/getProductsShown", data).then(response => {
			if (response !== "Invalid" && response !== "undefined") {
				if (response.status === "success") {
					setShownProductsData(response.data.shownProductsData)
					let totalRecord	= response.data.pagination.totalRecords,
						totalPage	= Math.ceil(totalRecord/limit)
					setTotalPageCount(totalPage)
				} else if (response.status === "failure") {
					toast.error(response.message)
				} else {
					toast.error(response.message.message)
				}
			}
			setTableLoader(false)
			setPageLoader(false)
		})
	}

	return (
		<div>
			{pageLoader ?
				<DetailSkeleton />
			:
				<React.Fragment>
					<ReportHeader range={dateRange} onDateChange={(range) => setDateRange(range)} />
					<div className="tab-content mt-3">
						<div className="d-flex justify-content-end align-items-center">
							{/* <h3>Search Phrase</h3> */}
							<div className="d-flex align-items-center">
								<label htmlFor="limit" className="me-2">
									Items Per Page:
								</label>
								<select id="limit" className="form-select" value={limit} onChange={(e) => setLimit(Number(e.target.value))} style={{ width: "100px", fontSize: "12px" }}>
									<option value={25}>25</option>
									<option value={30}>30</option>
									<option value={50}>50</option>
									<option value={100}>100</option>
								</select>
							</div>
						</div>
						<div>
							{tableLoader ?
								<TableSkeleton />
							:
								<Table className={`table ${shownProductsData.length > 0 ? "table-striped" : ""} mt-3 custom-bordered-table`}>
									<thead>
										<tr>
											<th>Product Name</th>
											<th>View Count</th>
										</tr>
									</thead>
									<tbody>
										{shownProductsData.length === 0 ? (
											<tr>
												<td colSpan={2}>
													<div className="emptyPlaceholder">
														<div>
															<div>
																<img src={require("./../../../assets/images/paper-plane-grey.png")} className='img-fluid' alt="No data found" />
															</div>
															<div className="rsInfoMsg disabled">
																<div className="flex-1">No records found.</div>
															</div>
														</div>
													</div>
												</td>
											</tr>
										) : (
											shownProductsData.map((row, index) => (
												<tr key={index}>
													<td>
														<div className='d-flex align-items-center'>
															<img
																src={row.image}
																alt={row.name}
																width="35"
																height="35"
																style={{
																	objectFit: "cover",
																	marginRight: "8px",
																}}
															/>
															<span>{row.name}</span>
														</div>
													</td>
													<td>{row.count}</td>
												</tr>
											))
										)}
									</tbody>
								</Table>
							}
							{totalPageCount > 1 &&
								<div className='d-flex justify-content-center mt-5'>
									<ReactPaginate
										nextLabel=">"
										onPageChange={(event) => setPage(event.selected)}
										page={page}
										pageRangeDisplayed={3}
										marginPagesDisplayed={2}
										pageCount={totalPageCount}
										previousLabel="<"
										pageClassName="page-item"
										pageLinkClassName="page-link"
										previousClassName="page-item"
										previousLinkClassName="page-link"
										nextClassName="page-item"
										nextLinkClassName="page-link"
										breakLabel="..."
										breakClassName="page-item"
										breakLinkClassName="page-link"
										containerClassName="pagination"
										activeClassName="active"
										renderOnZeroPageCount={null}
									/>
								</div>
							}
						</div>
					</div>
				</React.Fragment>
			}
		</div>
	);
};

export default ProdutsShown;