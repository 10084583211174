import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Col, Row, Table } from 'reactstrap';

const TableSkeleton = (props) => {
	return (
		<React.Fragment>
			<SkeletonTheme baseColor="#f7fafc" highlightColor="#eff1ff">				
				<Table className="table table-striped mt-3 custom-bordered-table">
					<thead>
						<tr>
							<th><Skeleton height={20} /></th>
							<th><Skeleton height={20} /></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><Skeleton height={20} /></td>
							<td><Skeleton height={20} /></td>
						</tr>
						<tr>
							<td><Skeleton height={20} /></td>
							<td><Skeleton height={20} /></td>
						</tr>
						<tr>
							<td><Skeleton height={20} /></td>
							<td><Skeleton height={20} /></td>
						</tr>
						<tr>
							<td><Skeleton height={20} /></td>
							<td><Skeleton height={20} /></td>
						</tr>
						<tr>
							<td><Skeleton height={20} /></td>
							<td><Skeleton height={20} /></td>
						</tr>
						<tr>
							<td><Skeleton height={20} /></td>
							<td><Skeleton height={20} /></td>
						</tr>
						<tr>
							<td><Skeleton height={20} /></td>
							<td><Skeleton height={20} /></td>
						</tr>
						<tr>
							<td><Skeleton height={20} /></td>
							<td><Skeleton height={20} /></td>
						</tr>
					</tbody>
				</Table>
			</SkeletonTheme>
		</React.Fragment>
	);
}

export default TableSkeleton;