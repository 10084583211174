import React from 'react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import DateRangePickerComponent from '../DaterangePicker';
import { SearchRoutes } from '../../Routes';
import { NavLink as NavLinkRRD } from "react-router-dom";

const ReportHeader = ({range, activeTab, onDateChange}) => {
	return (
		<React.Fragment>
			<Row className="mt-3 align-items-center justify-content-between">
				<Col xs="auto">
					<h2>Reports</h2>
				</Col>
				<Col xs={"auto"}>
					<DateRangePickerComponent
						onSelect={(range) => onDateChange(range)}
					/>
				</Col>
			</Row>
			<Row className='mt-3'>
				<Col xs={12}>
					<Nav tabs className='reportTabNavigation'>
						{SearchRoutes[1].subMenu.map((subMenu, index) => {
							return (
								<NavItem>
									<NavLink
										to={subMenu.layout + subMenu.path}
										tag={NavLinkRRD}
										className={`navItem ${subMenu.itemClass ? subMenu.itemClass : ""}`}
										activeClassName="active"
									>
										{subMenu.name}
									</NavLink>
								</NavItem>
							)
						})}
					</Nav>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default ReportHeader;